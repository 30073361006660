
document.addEventListener('DOMContentLoaded', () => {
// home slider
    if (document.querySelector('.hero-slider')) {
        const slider = tns({
            container: '.hero-slider',
            items: 1,
            center: true,
            gutter: 16,
            mouseDrag: true,
            controls: true,
            navPosition: 'bottom',
            nav: true,
            autoplay: true,
            autoplayButtonOutput: false,
            autoplayHoverPause: true,
            controlsText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-left-short\" viewBox=\"0 0 16 16\">\n" +
            "  <path fill-rule=\"evenodd\" d=\"M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z\"/>\n" +
            "</svg>", "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-right-short\" viewBox=\"0 0 16 16\">\n" +
            "  <path fill-rule=\"evenodd\" d=\"M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z\"/>\n" +
            "</svg>"],
            responsive: {
                992: {
                    gutter: 16,
                },
                768: {
                    gutter: 16,
                },
                0: {
                    gutter: 8,
                }
            }
        });
    }


    if (document.querySelector('.productGallery-slider')) {

        const productSlider = tns({
            container: '.productGallery-slider',
            items: 1,
            center: true,
            edgePadding: 0,
            gutter: 0,
            mouseDrag: false,
            autoWidth: false,
            autoplay: false,
            autoplayButtonOutput: false,
            autoplayHoverPause: true,
            autoplayTimeout: 3500,
            autoHeight: true,
            controls: true,
            navPosition: 'bottom',
            nav: true,
            controlsText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-left-short\" viewBox=\"0 0 16 16\">\n" +
            "  <path fill-rule=\"evenodd\" d=\"M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z\"/>\n" +
            "</svg>", "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-right-short\" viewBox=\"0 0 16 16\">\n" +
            "  <path fill-rule=\"evenodd\" d=\"M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z\"/>\n" +
            "</svg>"],
            responsive: {
                768: {
                    mouseDrag: true,
                }
            }
        });

        const images = document.querySelectorAll('.productGallery-slider .hero-slider__item--hide')

        images.forEach((image) => {
            image.classList.remove("hero-slider__item--hide")
        })
    }
});